.image-upload-page{

    .image-card{
        .delete-button{
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .buttons-pane{
        text-align: right;
        margin-bottom: 100px;
    }

    .file-input-card{
        position: relative;
        text-align: center;
        input[type=file]{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }
}