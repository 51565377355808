.property-detail-page{
    margin-top: 40px;
    margin-bottom: 40px;

    .property-card-carousel {
        position: sticky;
        top: 20px;
        margin-bottom: 10px;
        .carousel-slider{
            overflow: hidden;
            border-radius: $border-radius;

            &.loading-placeholder{
                height: 250px;
                @extend .loading-placeholder-background-animation;
            }
        }
    }

    .loading-text-line{
        @extend .loading-placeholder-background-animation;
    }
    .line-2{
        width: 75%;
    }
    .line-3{
        width: 50%;
    }
}


.whatsapp-card-container{
    $whatsapp-color-1: #075e54;
    .contact-whatsapp-button{
        //button-variant mixin is available in bootsrap scss
        @include button-variant($whatsapp-color-1, $whatsapp-color-1);
        @include media-breakpoint-down(xs){
            float: right;
        }
    }


    .date-range-button.btn-secondary:not(:disabled):not(.disabled).active{
        border: 1px solid $primary;
    }
}
