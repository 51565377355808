.footer{
    background-color: $gray-300;
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.footer-spacing{
    height: 100px;
}


.social-icon{
    font-size: 1.2em
}