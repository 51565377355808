.loading-overlay{
    position: fixed;
    top: 0px;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 1;

    .spinner{
        position: absolute;
        top: 50%;
    }
    .overlay{
        background-color: white;
        width: 100%;
        height: 100%;
        opacity: 0.7;
    }
}



.modal-loading-overlay{
    $spinner-width: 30px;
    position: absolute;
    top: 0px;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 1;

    .spinner{
        position: absolute;
        width: $spinner-width;
        height: $spinner-width;
        top: calc(50% - #{$spinner-width/2});
        margin-left: - #{$spinner-width/2}
    }
    .overlay{
        background-color: white;
        width: 100%;
        height: 100%;
        opacity: 0.7;
    }
}