
.home-featured-properties{
    margin-top: 10px;
    margin-bottom: 10px;

    .header{
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .home-featured-body{
        .property-image{
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    .home-featured-footer{
        padding-top: 10px;
        padding-bottom: 10px;
        .show-all{
            float: right;
        }
    }



    .loading-placeholder{
        .image-loading-placeholder{
            height: 100%;
            border-radius: $border-radius;
            @extend .loading-placeholder-background-animation;
        }
        .text-line{
            @extend .loading-placeholder-background-animation;
        }
        .line-2{
            width: 50%;
        }
    }

}