.filter-bar{
    padding-top: 30px;
    padding-bottom: 30px;
    .search-bar-container{
        position: relative;
        .search-icon{
            position: absolute;
            top: 10px;
            right: 15px;
            font-size: $font-size-base * 1.5;
        }
    }
}