.property-no-image{
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    background-color: $gray-400;
    display: flex;
    justify-content: center;
    align-items: center;
    .text{
        font-size: $font-size-base * 1.5;
        color: $gray-200;
        text-align: center;
        vertical-align: middle;
    }
}