.header-navbar{
    padding-top: 0px;
    padding-bottom: 0px;
    box-shadow: 0px 2px 100px 0px rgba(0,0,0,0.3);
    .navbar-brand{
        margin-left: 1em
    }

    .nav-link.active{
        font-weight: bold;
        margin-bottom: -5px;
        border-bottom: 5px solid $primary;
    }
}