
.property-card{
    border-top: none;
    border-left: none;
    border-right: none;

    .property-card-carousel .carousel-slider{
        overflow: hidden;
        border-radius: $border-radius;
    }
}

.property-card.loading-placeholder{
    .image-loading-placeholder{
        height: 100%;
        @extend .loading-placeholder-background-animation;
    }

    .text-line{
        @extend .loading-placeholder-background-animation;
    }

    .line-2{
        width: 75%;
    }

    .line-3{
        width: 50%;
    }
}