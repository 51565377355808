.sidebar{
    min-width: 200px;
    text-align: right;
    .sidebar-header{
        box-shadow: 0px 2px 100px 0px rgba(0,0,0,0.3);
        border-radius: 0px;
    }
    .nav-link.active{
        border-radius: 0px;
        background-color: lighten($primary, 50%);
        border-right: 5px solid $primary;
    }
}